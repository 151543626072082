import { t } from '@lingui/core/macro'
import { ConversationPath, UPLOAD_IN_PROGRESS } from 'common/channels'
import { Bubble, BubbleUpdate } from 'common/types'
import React, { useEffect, useState } from 'react'
import { serverTimestamp } from 'shared/firebase/serverValue'
import { FirebaseKey } from 'shared/types/utils'
import {
  uploadImage,
  uploadSound,
} from '../components/conversation/fileUploader'
import { update } from '../firebaseMethods'

async function updateBubble(
  bubblePaths: ConversationPath[],
  updatedBubble: BubbleUpdate,
): Promise<void> {
  await Promise.all(bubblePaths.map((path) => update(path, updatedBubble)))
}

export const useMessagePoster = (
  userId: FirebaseKey,
  saveBubble: (bubble: Bubble) => Promise<ConversationPath[]>,
) => {
  const [text, setText] = useState('')
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null)
  const [photo, setPhoto] = useState<File | null>(null)
  const [error, setError] = useState<string | undefined>()

  useEffect(() => {
    if (text || photo || audioBlob) {
      resetError()
    }
  }, [text, photo, audioBlob])

  const resetError = () => setError(undefined)

  async function sendMessage(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    resetError()

    if (!text && !audioBlob && !photo) return

    const bubble: Bubble = {
      timestamp: serverTimestamp(),
      userId,
      ...(photo && { image: { url: UPLOAD_IN_PROGRESS, path: '' } }),
      ...(audioBlob
        ? { sound: { url: UPLOAD_IN_PROGRESS, path: '' } }
        : text
          ? { text }
          : {}),
    }

    setText('')
    clearAudio()
    clearPhoto()

    const bubblePaths = await saveBubble(bubble)

    try {
      let updatedBubble: BubbleUpdate = {}
      if (photo) {
        const [path, url] = await uploadImage(photo)
        if (!url) {
          setError(t`Erreur lors de l'envoi de l'image`)
          return
        }
        updatedBubble = { ...updatedBubble, image: { url, path } }
      }

      if (audioBlob) {
        const [path, url] = await uploadSound(audioBlob)
        if (!path) {
          setError(t`Erreur lors de l'envoi de l'audio`)
          return
        }
        updatedBubble = { ...updatedBubble, sound: { url, path } }
      }

      await updateBubble(bubblePaths, updatedBubble)
    } catch (_error) {
      setError(t`Erreur lors de l'envoi du message`)
    }
  }

  function clearAudio() {
    setAudioBlob(null)
    resetError()
  }

  function clearPhoto() {
    setPhoto(null)
    resetError()
  }

  return {
    sendMessage,
    clearAudio,
    clearPhoto,
    text,
    setText,
    audioBlob,
    setAudioBlob,
    setPhoto,
    photo,
    error,
  }
}
