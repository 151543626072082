import { Trans } from '@lingui/react/macro'

import React, { useEffect, useMemo, useRef, useState } from 'react'

import { TUTORIAL_VIDEOS } from './tutorial-videos'
import { VideoTrackerAction } from 'common/databaseSchema'
import { VideoSource } from 'common/types'
import { serverTimestamp } from 'shared/firebase/serverValue'
import { useUser } from '../../components/UserProvider'
import { push, set } from '../../firebaseMethods'
import useIntersectionObserver from '../../hooks/useIntersectionObserver'

function formatVideoName(src: string) {
  return src
    .replace(/^\/src\/modules\/Live\/tutorial-videos\//, '')
    .replace(/\.mp4$/, '')
    .replace(/%20/g, ' ')
}

export async function trackVideoAction(
  uid: string,
  action: VideoTrackerAction,
  source: VideoSource,
) {
  return await push(`videoAction/${uid}`, {
    action,
    timestamp: serverTimestamp(),
    source,
  })
}

interface VideoComponentProps {
  video: {
    src: string
    poster: string
  }
  source: VideoSource
}

const VideoComponent: React.FC<VideoComponentProps> = ({
  video: { src, poster },
  source,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const { uid, role } = useUser()

  const [maxPlayProgress, setMaxPlayProgress] = useState(0)
  const [databaseVideoReferenceKey, setDatabaseVideoReferenceKey] =
    useState<string>()

  // store last maxPlayProgress to save it on unmount
  const latestMaxProgressRef = useRef(0)

  useEffect(() => {
    latestMaxProgressRef.current = maxPlayProgress
  }, [maxPlayProgress])

  useEffect(() => {
    const video = videoRef.current
    let progressInterval: NodeJS.Timeout

    // save on pause, every 10s and on cleanup
    const saveProgress = async () => {
      if (databaseVideoReferenceKey && role === 'AS') {
        await set(
          `videos/${uid}/${databaseVideoReferenceKey}/progress`,
          latestMaxProgressRef.current,
        )
      }
    }

    const onVideoPlay = async () => {
      if (role === 'AS') {
        const reference = push(`videos/${uid}`, {
          videoId: formatVideoName(src),
          timestamp: serverTimestamp(),
          progress: 0,
          source,
        })
        setDatabaseVideoReferenceKey(reference.key)
      }

      progressInterval = setInterval(saveProgress, 10000)
    }

    const onVideoTimeUpdate = () => {
      if (video === null) return
      const currentProgress = video.currentTime / video.duration
      setMaxPlayProgress((cur) => Math.max(cur, currentProgress))
    }

    const onVideoPause = () => {
      clearInterval(progressInterval)
      saveProgress()
    }

    if (video) {
      video.addEventListener('play', onVideoPlay)
      video.addEventListener('timeupdate', onVideoTimeUpdate)
      video.addEventListener('pause', onVideoPause)
    }

    return () => {
      if (video) {
        video.removeEventListener('play', onVideoPlay)
        video.removeEventListener('timeupdate', onVideoTimeUpdate)
        video.removeEventListener('pause', onVideoPause)
      }
      clearInterval(progressInterval)

      if (databaseVideoReferenceKey && latestMaxProgressRef.current > 0) {
        saveProgress()
      }
    }
  }, [src, uid, databaseVideoReferenceKey, source, role])

  const handleIntersection: IntersectionObserverCallback = (entries) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        if (videoRef.current && videoRef.current.played.length > 0) {
          videoRef.current.pause()
        }
      }
    })
  }

  useIntersectionObserver(videoRef, handleIntersection, { threshold: 1 })

  return (
    <video
      ref={videoRef}
      controls
      className="max-h-[70vh]"
      poster={poster}
      preload="auto"
    >
      <source src={src} type="video/mp4" />
      <p>
        <Trans>Désolé, ce navigateur ne peut pas lire cette vidéo.</Trans>
        <Trans>
          Voici
          <a href={src}>un lien pour télécharger la vidéo</a>.
        </Trans>
      </p>
    </video>
  )
}

export const TutorialCarousel: React.FC<{
  isExpanded: boolean
  source: VideoSource
}> = ({ isExpanded, source }) => {
  const startIndex = useMemo(
    () => Math.floor(Math.random() * TUTORIAL_VIDEOS.length),
    [],
  )
  const containerRef = useRef<HTMLDivElement>(null)

  const { uid, role } = useUser()

  useEffect(() => {
    if (role === 'AS') {
      if (isExpanded) {
        // track when user expands the popup or when user go on the menu
        trackVideoAction(
          uid,
          source === 'menu' ? 'MENU_DISPLAYED' : 'POPUP_EXPANDED',
          source,
        )
      }
    }
  }, [source, uid, isExpanded, role])

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current
      const videoHeight = container.scrollHeight / TUTORIAL_VIDEOS.length
      const scrollPosition = videoHeight * startIndex

      container.scrollTo({ top: scrollPosition, behavior: 'instant' })
    }
  }, [startIndex, isExpanded])

  return isExpanded ? (
    <div
      ref={containerRef}
      className="flex snap-y snap-mandatory flex-col items-center gap-2 overflow-y-scroll"
    >
      {TUTORIAL_VIDEOS.map((video, index) => (
        <div key={index} className="snap-center items-center justify-center">
          <VideoComponent video={video} source={source} />
        </div>
      ))}
    </div>
  ) : (
    <img src={TUTORIAL_VIDEOS[startIndex].poster} />
  )
}
