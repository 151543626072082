import { Trans } from '@lingui/react/macro'
import { DateTime } from 'luxon'
import React, { useEffect, useMemo, useState } from 'react'
import { Facility } from 'shared/types/live'
import { ms, noonDate } from 'shared/utils/time'
import { isWithinTimeRange } from 'shared/utils/timeRange'
import { collator } from 'shared/utils/web/collator'
import { getRealZone, roomIsInZoneAtTime } from 'shared/utils/zone'
import { useUser } from '../../components/UserProvider'
import { useFirebase } from '../../hooks/useFirebase'
import { AlertProvider } from './AlertContext'
import { ContactsProvider } from './contactsProvider'
import { Menu } from './Menu'
import { RoomList } from './RoomList'
import { RoomNamesProvider } from './RoomNamesContext'
import { TutorialPopupDialog } from './TutorialPopupDialog'

export const Live: React.FC<{
  facilityId: string
  facility: Facility
}> = ({ facilityId, facility }) => {
  const { devices, monitoringTimeRange } = facility
  const [isMonitoring, setIsMonitoring] = useState(false)
  const [alertsDate, setAlertsDate] = useState('2000-01-01')
  const [now, setNow] = useState(DateTime.now())
  const user = useUser()

  // Check time periodically to compare with monitoringTimeRange
  // Also updates visible rooms based on zones and current time
  useEffect(() => {
    const recomputeState = () => {
      const now = DateTime.now()
      const isMonitoring = isWithinTimeRange(now, monitoringTimeRange)
      const alertsDate = noonDate(now)

      setIsMonitoring(isMonitoring)
      setAlertsDate(alertsDate)
      setNow(now)
    }

    recomputeState()

    const intervalId = setInterval(recomputeState, ms(1, 'minute'))

    return () => {
      clearInterval(intervalId)
    }
  }, [monitoringTimeRange])

  const { data: zones } = useFirebase(`zones/${facilityId}`)

  const selectedZone = useMemo(() => {
    if (zones && user.role === 'AS' && user.zoneId !== undefined)
      return zones[user.zoneId]

    return undefined
  }, [user, zones])

  const realZone = useMemo(() => {
    if (selectedZone === undefined) return undefined
    return getRealZone(zones ?? {}, selectedZone, now)
  }, [zones, selectedZone, now])

  const sortedDevices = useMemo(
    () =>
      Object.entries(devices ?? {})
        .filter(([, { status }]) => status !== 'pending' && status !== 'spare')
        .sort(([, { room: roomA }], [, { room: roomB }]) =>
          collator.compare(roomA, roomB),
        ),
    [devices],
  )

  const displayedDevices = useMemo(() => {
    if (realZone === undefined) return sortedDevices

    return sortedDevices
      .filter(([, { room }]) => roomIsInZoneAtTime(room, now, realZone))
      .filter(
        ([, { monitoringTimeRange }]) =>
          monitoringTimeRange === undefined ||
          isWithinTimeRange(now, monitoringTimeRange),
      )
  }, [sortedDevices, realZone, now])

  const rooms = sortedDevices.map(([_, device]) => device.room)

  return (
    <AlertProvider>
      <RoomNamesProvider facilityId={facilityId} rooms={rooms}>
        <TutorialPopupDialog />
        <div className="sticky top-0 z-10">
          <ContactsProvider facilityId={facilityId}>
            <Menu facilityId={facilityId} />
          </ContactsProvider>
          {selectedZone && (
            <div className="bg-background py-4 text-center">
              <div>
                <Trans>
                  Surveillance de <b>{selectedZone.name}</b>
                </Trans>
              </div>
              {selectedZone !== realZone && (
                <div className="text-xs">
                  <Trans>Week-ends et jours fériés</Trans>
                </div>
              )}
            </div>
          )}
        </div>
        <RoomList
          {...{
            isMonitoring,
            displayedDevices,
            alertsDate,
            facility,
            realZone,
          }}
        />
      </RoomNamesProvider>
    </AlertProvider>
  )
}
