import { Trans } from '@lingui/react/macro'
import { useState } from 'react'
import { Zone } from 'shared/types/fleet'
import { Deferred } from 'shared/utils/web/deferred'
import { zoneSorter } from 'shared/utils/zone'
import {
  Dialog,
  USER_BACK_BUTTON,
  USER_CLOSED_DIALOG,
} from '../../components/Dialog'
import { ListItem } from '../../components/ListItem'
import { Button } from '../../components/ui/Button'
import { useUser } from '../../components/UserProvider'
import { remove, set } from '../../firebaseMethods'
import Arrow from '../../icons/arrow.svg?react'

// Remember to update HTML in HelpDialog accordingly
export const ZoneSelectionDialog: React.FC<{
  deferred: Deferred<void>
  zones: Record<string, Zone>
}> = ({ deferred, zones }) => {
  const [showWarning, setShowWarning] = useState(true)

  return (
    <Dialog onClose={() => deferred.reject(USER_CLOSED_DIALOG)}>
      <div
        className="flex cursor-pointer flex-row items-center space-x-2"
        onClick={() => deferred.reject(USER_BACK_BUTTON)}
      >
        <Arrow className="w-4" fill="currentColor" />
        <span className="flex-1">
          <Trans>Sélection des chambres</Trans>
        </span>
      </div>
      {showWarning ? (
        <ZoneSelectionWarning
          cancel={() => deferred.reject(USER_CLOSED_DIALOG)}
          proceed={() => setShowWarning(false)}
        />
      ) : (
        <ZoneSelection deferred={deferred} zones={zones} />
      )}
    </Dialog>
  )
}

const ZoneSelectionWarning: React.FC<{
  cancel: () => void
  proceed: () => void
}> = ({ cancel, proceed }) => {
  return (
    <div className="flex flex-col gap-6">
      <div>
        <Trans>
          Modifier ce réglage peut faire que certaines chambres ne sont plus
          surveillées par aucun téléphone.
        </Trans>
      </div>
      <div className="flex justify-center">
        <div className="border-destructive flex h-20 w-20 items-center justify-center rounded-full border-4">
          <div className="text-destructive text-6xl font-bold">!</div>
        </div>
      </div>
      <Trans>Êtes-vous sûr(e) de vouloir changer de zone ?</Trans>
      <Button variant="destructive" size="lg" onClick={proceed}>
        <Trans>Oui, j'ai compris</Trans>
      </Button>
      <Button variant="outline" size="full" onClick={cancel}>
        <Trans>Non, annuler</Trans>
      </Button>
    </div>
  )
}

const ZoneSelection: React.FC<{
  deferred: Deferred<void>
  zones: Record<string, Zone>
}> = ({ deferred, zones }) => {
  const user = useUser()

  function selectZone(zoneId: string) {
    set(`users/${user.uid}/zoneId`, zoneId)
    deferred.reject(USER_CLOSED_DIALOG)
  }

  function deselectZone() {
    remove(`users/${user.uid}/zoneId`)
    deferred.reject(USER_CLOSED_DIALOG)
  }

  return (
    <>
      <div className="flex flex-col space-y-3 overflow-y-auto">
        <ListItem onClick={() => deselectZone()}>
          <Trans>Toutes</Trans>
        </ListItem>
        {Object.entries(zones)
          .filter(([_, { isHoliday }]) => !isHoliday)
          .sort(zoneSorter)
          .map(([zoneId, zone]) => (
            <ListItem key={zoneId} onClick={() => selectZone(zoneId)}>
              {zone.name}
            </ListItem>
          ))}
      </div>
      <div className="flex flex-row">
        <Button
          variant="outline"
          size="full"
          onClick={() => deferred.reject(USER_CLOSED_DIALOG)}
        >
          <Trans>Fermer</Trans>
        </Button>
      </div>
    </>
  )
}
