import { Trans } from '@lingui/react/macro'
import React from 'react'
import { Centered } from 'shared/components/Centered'
import { Button } from '../../components/ui/Button'
import Offline from '../../icons/offline.svg?react'

export const OfflineScreen: React.FC = () => (
  <div className="bg-background text-primary flex min-h-screen flex-col text-xl">
    <Centered>
      <div className="flex flex-col space-y-6">
        <div>
          <Offline />
        </div>
        <Trans>Pas de connexion…</Trans>
        <Button onClick={() => location.reload()}>
          <Trans>Rafraîchir</Trans>
        </Button>
      </div>
    </Centered>
  </div>
)
