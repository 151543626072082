import { DateTime } from 'luxon'
import React from 'react'
import { useInterval } from 'shared/hooks/useInterval'
import { ms } from 'shared/utils/time'

const CHECK_INTERVAL = ms(20, 'minutes')

const themeStorageKey = 'theme'

export type Theme = 'light' | 'dark' | 'auto' | 'tech'

export function currentTheme() {
  const theme = localStorage.getItem(themeStorageKey)
  return (theme as Theme) ?? 'dark'
}

export function setTheme(theme: Theme) {
  localStorage.setItem(themeStorageKey, theme)
  updateTheme()
}

function setDarkMode(isDark: boolean) {
  document.documentElement.classList.remove('dark')
  if (isDark) document.documentElement.classList.add('dark')
}

export function setTemporaryTheme(theme: Theme) {
  document.documentElement.classList.remove(currentTheme())
  document.documentElement.classList.add(theme)
}

export function removeTemporaryTheme(theme: Theme) {
  document.documentElement.classList.remove(theme)
  document.documentElement.classList.add(currentTheme())
}

function isTimeDark() {
  const hour = DateTime.now().hour // use local time zone
  const shouldBeDark = hour >= 20 || hour < 7
  return shouldBeDark
}

function updateTheme() {
  const theme = currentTheme()

  setDarkMode(theme === 'dark' || (theme === 'auto' && isTimeDark()))
}

export const Theme: React.FC = () => {
  useInterval(updateTheme, CHECK_INTERVAL)

  return null
}
