import { Trans } from '@lingui/react/macro'
import React from 'react'
import { roomDisplayName } from 'shared/utils/room'
import { Deferred } from 'shared/utils/web/deferred'
import { Dialog, USER_CLOSED_DIALOG } from '../../components/Dialog'
import { Button } from '../../components/ui/Button'

export const AcknowledgeAlertDialog: React.FC<{
  room: string
  roomName: string
  deferred: Deferred<void>
}> = ({ room, roomName, deferred }) => {
  return (
    <Dialog onClose={() => deferred.reject(USER_CLOSED_DIALOG)}>
      <div>
        <Trans>Nouvelle alerte {roomDisplayName(room)}</Trans>
        {roomName && (
          <span>
            <br />
            {roomName}
          </span>
        )}
      </div>
      <div className="flex flex-row">
        <Button
          onClick={() => deferred.resolve()}
          variant="outline"
          size="full"
        >
          <Trans>OK</Trans>
        </Button>
      </div>
    </Dialog>
  )
}
