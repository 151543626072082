import { techChannelId } from 'common/channels'
import { CheckCheck } from 'lucide-react'
import { useCallback, useContext, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { FacilityId } from 'shared/types/utils'
import { cn } from 'shared/utils/web/cn'
import { Button } from '../../components/ui/Button'
import { facilitiesContext } from '../../contexts/FacilitiesProvider'
import { useConversationsContext } from './ConversationsContext'
import { useLastReadContext } from './LastReadContext'
import { UnreadCount } from './UnreadCount'

export const Messaging = () => {
  const navigate = useNavigate()
  const params = useParams<{ facilityId: string }>()

  const { facilities } = useContext(facilitiesContext)

  const [isSelectionEnable, setSelectionEnable] = useState(false)
  const [selectedFacilityIds, setSelectedFacilityIds] = useState<
    Set<FacilityId>
  >(new Set())

  const { lastReadTS, lastReadTechnicianTS, markConversationsAsRead } =
    useLastReadContext()

  const { techncianConversations, facilityConversations } =
    useConversationsContext()

  const toggleGroupFacilityId = useCallback(
    (facilityId: FacilityId) => {
      if (selectedFacilityIds.has(facilityId)) {
        selectedFacilityIds.delete(facilityId)
      } else selectedFacilityIds.add(facilityId)
      setSelectedFacilityIds(new Set(selectedFacilityIds))
    },
    [selectedFacilityIds],
  )

  const selectAllFacilityIds = useCallback(
    () => setSelectedFacilityIds(new Set(Object.keys(facilities))),
    [facilities],
  )

  const resetGroupFacilityIds = () => setSelectedFacilityIds(new Set())

  return (
    <div className="flex max-h-[calc(100vh-40px)] flex-row">
      <div className="flex max-h-full flex-col overflow-y-auto">
        <div className="bg-header text-header-foreground sticky top-0 flex flex-col gap-2 p-2 shadow-xl">
          <div className="flex items-center justify-between">
            <input
              type="checkbox"
              id="group"
              checked={isSelectionEnable}
              onChange={() => {
                if (isSelectionEnable) navigate('/messaging')
                else navigate('/messaging/multi')
                setSelectionEnable(!isSelectionEnable)
                resetGroupFacilityIds()
              }}
              className="size-4"
            />
            <label
              htmlFor="group"
              className="max-w-80 grow cursor-pointer pl-4"
            >
              Sélection ({selectedFacilityIds.size})
            </label>
            <Button
              disabled={!isSelectionEnable}
              onClick={() =>
                selectedFacilityIds.size > 0
                  ? resetGroupFacilityIds()
                  : selectAllFacilityIds()
              }
              className="bg-background text-primary hover:bg-background/60 shadow"
            >
              {selectedFacilityIds.size > 0 ? 'Aucun' : 'Tous'}
            </Button>
          </div>
          <Button
            onClick={() =>
              markConversationsAsRead([...selectedFacilityIds.keys()])
            }
            disabled={!isSelectionEnable || !selectedFacilityIds.size}
            className="bg-background text-primary hover:bg-background/60 gap-2 shadow"
          >
            Marquez comme lu la sélection
            <CheckCheck />
          </Button>
        </div>
        {Object.entries(facilities).map(([facilityId, facility]) => (
          <div
            key={facilityId}
            className={cn(
              'flex cursor-pointer items-center px-2 hover:bg-gray-600',
              facilityId === params.facilityId && !isSelectionEnable
                ? 'bg-gray-500'
                : '',
            )}
            onClick={() =>
              !isSelectionEnable && navigate(`/messaging/${facilityId}`)
            }
          >
            {isSelectionEnable ? (
              <input
                type="checkbox"
                id={`group-${facilityId}`}
                checked={selectedFacilityIds.has(facilityId)}
                onChange={() => toggleGroupFacilityId(facilityId)}
                className="size-4"
              />
            ) : (
              <div className="size-4" />
            )}
            <label
              className="h-full max-w-80 grow cursor-pointer truncate py-0.5 pl-4"
              htmlFor={`group-${facilityId}`}
            >
              {facility.name}
            </label>
            <UnreadCount
              conversation={facilityConversations[facilityId] ?? {}}
              lastReadTS={lastReadTS[facilityId]}
            />
            <UnreadCount
              conversation={techncianConversations[facilityId] ?? {}}
              lastReadTS={lastReadTechnicianTS[facilityId]}
              conversationId={techChannelId}
            />
          </div>
        ))}
      </div>
      <div className="flex grow">
        <Outlet context={{ selectedFacilityIds }} />
      </div>
    </div>
  )
}
