import { osoChannelId, techChannelId } from 'common/channels'
import { DatabaseSchema } from 'common/databaseSchema'
import { Conversation } from 'common/types'
import React, { createContext, useContext, useMemo } from 'react'
import { Centered } from 'shared/components/Centered'
import { MergedType } from 'shared/hooks/createUseMergedFirebase'
import { FacilityId } from 'shared/types/utils'
import { facilitiesContext } from '../../contexts/FacilitiesProvider'
import { useMergedFirebase } from '../../hooks/useMergedFirebase'

interface ConversationsContextType {
  techncianConversations: Record<string, Conversation | undefined>
  facilityConversations: Record<string, Conversation | undefined>
}

const ConversationsContext = createContext<ConversationsContextType | null>(
  null,
)

export const ConversationsProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { facilities } = useContext(facilitiesContext)

  const refOsoChannelPathsMap = useMemo(
    () =>
      Object.keys(facilities).reduce<Record<FacilityId, string>>(
        (acc, facilityId) => {
          acc[facilityId] = `conversations/${facilityId}/${osoChannelId}`
          return acc
        },
        {},
      ),
    [facilities],
  )

  const {
    data: facilityConversations,
    loading,
    error,
  } = useMergedFirebase<
    MergedType<'conversations/${string}/${string}', DatabaseSchema>
  >(refOsoChannelPathsMap)

  const refTechChannelPathsMap = useMemo(
    () =>
      Object.keys(facilities).reduce<Record<FacilityId, string>>(
        (acc, facilityId) => {
          acc[facilityId] = `conversations/${facilityId}/${techChannelId}`
          return acc
        },
        {},
      ),
    [facilities],
  )

  const {
    data: techncianConversations,
    loading: technicianLoading,
    error: techncianError,
  } = useMergedFirebase<
    MergedType<'conversations/${string}/${string}', DatabaseSchema>
  >(refTechChannelPathsMap)

  if (loading || technicianLoading) return <Centered>Chargement...</Centered>
  if (error || techncianError) return <Centered>Erreur</Centered>

  return (
    <ConversationsContext.Provider
      value={{
        techncianConversations,
        facilityConversations,
      }}
    >
      {children}
    </ConversationsContext.Provider>
  )
}

export const useConversationsContext = () => {
  const context = useContext(ConversationsContext)
  if (!context) {
    throw new Error(
      'useConversationsContext must be used within a ConversationsContext.Provider',
    )
  }
  return context
}
