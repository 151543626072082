import { MouseEvent, TouchEvent, useCallback, useRef } from 'react'

const MOVE_THRESHOLD = 20

const isTouchEvent = (event: TouchEvent | MouseEvent): event is TouchEvent => {
  return 'touches' in event
}

const getCoordsFromEvent = (event: TouchEvent | MouseEvent) => {
  if (isTouchEvent(event)) {
    return { x: event.touches[0].clientX, y: event.touches[0].clientY }
  }
  return { x: event.clientX, y: event.clientY }
}

const isMoving = (
  oldEvent: TouchEvent | MouseEvent,
  newEvent: TouchEvent | MouseEvent,
) => {
  const oldCoords = getCoordsFromEvent(oldEvent)
  const newCoords = getCoordsFromEvent(newEvent)

  return (
    Math.pow(oldCoords.x - newCoords.x, 2) +
      Math.pow(oldCoords.y - newCoords.y, 2) >
    MOVE_THRESHOLD * MOVE_THRESHOLD
  )
}

export const useLongPress = (
  callback: (e: TouchEvent | MouseEvent) => void,
  delay = 300,
) => {
  const timeout = useRef<NodeJS.Timeout>()
  const originalEvent = useRef<TouchEvent | MouseEvent>()

  const clear = useCallback(() => {
    // clearTimeout and removeEventListener
    if (timeout.current) clearTimeout(timeout.current)
  }, [])

  const start = useCallback(
    (event: TouchEvent | MouseEvent) => {
      event.persist()
      originalEvent.current = event
      timeout.current = setTimeout(() => callback(event), delay)
    },
    [callback, delay],
  )

  const handleMove = useCallback(
    (event: TouchEvent | MouseEvent) => {
      if (originalEvent.current && isMoving(originalEvent.current, event)) {
        clear()
      }
    },
    [originalEvent, clear],
  )

  return {
    onMouseDown: (e: TouchEvent | MouseEvent) => start(e),
    onTouchStart: (e: TouchEvent | MouseEvent) => start(e),
    onMouseUp: clear,
    onMouseLeave: clear,
    onTouchEnd: clear,
    onMouseMove: (e: TouchEvent | MouseEvent) => handleMove(e),
    onTouchMove: (e: TouchEvent | MouseEvent) => handleMove(e),
  } as const
}
