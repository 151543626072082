import { Trans } from '@lingui/react/macro'
import { translateAlertDisplayText } from 'common/alert.i18n'
import { alertListener } from 'common/alertQueries'
import { CircleCheck, Pencil } from 'lucide-react'
import { DateTime } from 'luxon'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { serverTimestamp } from 'shared/firebase/serverValue'
import { useInterval } from 'shared/hooks/useInterval'
import {
  Alert as Alert_,
  AlertType,
  enableAudioPlayer,
  Ownership,
} from 'shared/types/alert'
import { dateTimeFromISO, ms, noonDate } from 'shared/utils/time'
import { cn } from 'shared/utils/web/cn'
import { toast } from 'sonner'
import { Audit } from '../../components/Audit'
import { Player } from '../../components/Player'
import { Button } from '../../components/ui/Button'
import { useUser } from '../../components/UserProvider'
import { remove, set, update } from '../../firebaseMethods'
import { AlertContext } from './AlertContext'
import { useAlertFeedbackModalContext } from './AlertFeedbackModalContext'

const TOAST_DURATION = ms(10, 'second')

export const Alert: React.FC<{
  alert: Alert_
  serial: string
  alertId: string
}> = ({ alert, serial, alertId }) => {
  const alertRef = useRef<HTMLDivElement>(null)

  const { notificationAlertId } = useContext(AlertContext)
  const { setAlertFeedbackDetails, displayedRoomName } =
    useAlertFeedbackModalContext()

  const { uid } = useUser()

  const [maxPlayProgress, setMaxPlayProgress] = useState(0)

  const alertDate = dateTimeFromISO(alert.date)
  const date = noonDate(alertDate)

  const onPlayPauseEvent = useCallback(
    async (progress: number) => {
      if (isNaN(progress)) return

      const max = Math.max(progress, maxPlayProgress)

      setMaxPlayProgress(max)

      await set(
        `alerts/${date}/${serial}/${alertId}/listeners/${uid}`,
        alertListener(max),
      )
    },
    [alertId, date, maxPlayProgress, serial, uid],
  )

  const onPlayProgress = useCallback((progress: number) => {
    if (isNaN(progress)) return
    setMaxPlayProgress((maxPlayProgress) => Math.max(progress, maxPlayProgress))
  }, [])

  async function takeAlertOwnership() {
    const ownership: Ownership = {
      uid,
      startTS: serverTimestamp(),
      playProgress: maxPlayProgress,
    }
    await set(`alerts/${date}/${serial}/${alertId}/ownership`, ownership)
  }

  async function releaseAlertOwnership() {
    await remove(`alerts/${date}/${serial}/${alertId}/ownership`)
  }

  async function validateAlert() {
    const alertDetails = { date, serial, alertId }

    toast.custom(
      (t) => (
        <AlertValidateToast
          onClose={() => toast.dismiss(t)}
          onOpenFeedbackDialog={() => {
            toast.dismiss(t)
            setAlertFeedbackDetails(alertDetails)
          }}
          displayedRoomName={displayedRoomName}
        />
      ),
      { duration: TOAST_DURATION },
    )
    await update(`alerts/${date}/${serial}/${alertId}/ownership`, {
      endTS: serverTimestamp(),
      playProgress: maxPlayProgress,
    })
  }

  // One time effect that should run only once per alert
  useEffect(() => {
    const doScroll = notificationAlertId === alertId
    if (doScroll)
      alertRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' })
  }, [notificationAlertId, alertId])

  return (
    <div
      ref={alertRef}
      className={cn(
        alertBackgroundColor(alert.type),
        'text-alert relative flex flex-col space-y-3 rounded-lg p-3',
      )}
    >
      <div className="flex flex-row flex-wrap items-center space-x-4">
        {alert.auditUrl && (alert.auditUrlExpiration ?? 0) >= Date.now() ? (
          <Audit auditUrl={alert.auditUrl} />
        ) : enableAudioPlayer(alert.type) ? (
          <Player
            soundURI={alert.soundURL}
            onPlayPauseEvent={onPlayPauseEvent}
            onPlayProgress={onPlayProgress}
            isPreloadEnabled
          />
        ) : null}
        <div>
          <b>{alertDate.toLocaleString(DateTime.TIME_SIMPLE)}</b>
        </div>
        <div>{translateAlertDisplayText(alert)}</div>
      </div>
      <AlertHandling
        alert={alert}
        takeAlertOwnership={takeAlertOwnership}
        releaseAlertOwnership={releaseAlertOwnership}
        validateAlert={validateAlert}
      />
    </div>
  )
}

// Remember to update HTML in HelpDialog accordingly
const AlertHandling: React.FC<{
  alert: Alert_
  takeAlertOwnership: () => void
  releaseAlertOwnership: () => void
  validateAlert: () => void
}> = ({ alert, takeAlertOwnership, releaseAlertOwnership, validateAlert }) => {
  const { uid } = useUser()

  if (alert.ownership === undefined) {
    return (
      <div className="my-3 flex flex-row justify-center">
        <Button
          onClick={takeAlertOwnership}
          variant="outline"
          type="button"
          size="full"
        >
          <Trans>Je m'en charge !</Trans>
        </Button>
      </div>
    )
  }

  if (alert.ownership.endTS) {
    const now = DateTime.now()
    const endDateTime = DateTime.fromMillis(alert.ownership.endTS)

    const formattedTimeSinceOwnership = endDateTime.toRelative({ base: now })

    return (
      <div className="text-center">
        <Trans>Alerte traitée {formattedTimeSinceOwnership}</Trans>
      </div>
    )
  }

  if (alert.ownership.uid === uid) {
    return <AlertOwnership {...{ releaseAlertOwnership, validateAlert }} />
  }

  return (
    <div className="text-center">
      <Trans>Alerte prise en charge</Trans>
    </div>
  )
}

const AlertOwnership: React.FC<{
  releaseAlertOwnership: () => void
  validateAlert: () => void
}> = ({ releaseAlertOwnership, validateAlert }) => {
  const TIME = ms(5, 'second')

  const progress = useProgressInterval(TIME)

  return (
    <div className="flex flex-col space-y-3">
      <div className="text-pretty text-center text-sm">
        <Trans>
          Évaluez la situation et déterminez les actions appropriées.
          <br />
          Une fois l'alerte traitée, appuyez sur le bouton vert.
        </Trans>
      </div>
      <div className="flex flex-row justify-between gap-2">
        <Button
          onClick={() => releaseAlertOwnership()}
          variant="outline"
          size="lg"
          className="grow px-1"
        >
          <Trans>Annuler</Trans>
        </Button>
        <Button
          onClick={() => validateAlert()}
          className="relative grow overflow-hidden px-1"
          size="lg"
          variant="submit"
          disabled={progress !== 0}
        >
          <span className="relative z-10 flex flex-row">
            <CircleCheck className="mr-2" />
            <Trans>Alerte traitée</Trans>
          </span>
          <div
            className="absolute inset-0 bg-black opacity-50 transition-all duration-100 ease-linear"
            style={{ left: `${100 - progress}%` }}
          />
        </Button>
      </div>
    </div>
  )
}

function alertBackgroundColor(alertType: AlertType) {
  if (alertType === 'WAKEUP') return 'bg-background-alert-wakeup'
  return 'bg-background-alert'
}

const useProgressInterval = (duration: number) => {
  const MAX_PROGRESS = 100
  const [progress, setProgress] = useState(MAX_PROGRESS)

  const updatProgress = () => {
    setProgress((prevProgress) => {
      if (prevProgress > 0) {
        return prevProgress - MAX_PROGRESS / (duration / 100)
      }
      return 0
    })
  }

  useInterval(updatProgress, MAX_PROGRESS)

  return progress
}

const AlertValidateToast = ({
  onClose,
  onOpenFeedbackDialog,
  displayedRoomName,
}: {
  onClose: () => void
  onOpenFeedbackDialog: () => void
  displayedRoomName: string
}) => {
  const progress = useProgressInterval(TOAST_DURATION)

  useEffect(() => {
    if (progress === 0) {
      onClose()
    }
  }, [progress, onClose])

  return (
    <div className="bg-background-dialog text-dialog flex flex-col gap-2 rounded-sm p-2">
      <h1 className="flex flex-row items-center text-lg">
        <CircleCheck className="mr-2" />
        <Trans>Alerte traitée {displayedRoomName}</Trans>
      </h1>
      <div className="flex flex-row justify-between gap-2">
        <Button
          variant="outline"
          onClick={onClose}
          className="relative overflow-hidden"
        >
          <span className="relative z-10">
            <Trans>Fermer</Trans>
          </span>
          <div
            className="absolute inset-0 bg-black opacity-10 transition-all duration-100 ease-linear"
            style={{ right: `${100 - progress}%` }}
          />
        </Button>
        <Button variant="secondary" onClick={onOpenFeedbackDialog}>
          <Pencil className="mr-2" />
          <Trans>Commenter cette alerte</Trans>
        </Button>
      </div>
    </div>
  )
}
