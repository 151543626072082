import React, { createContext, useContext, useState } from 'react'

type AlertFeedbackModalContextType = {
  open: boolean
  setAlertFeedbackDetails: (details: AlertDetails | null) => void
  details: AlertDetails | null
  displayedRoomName: string
}

export type AlertDetails = {
  date: string
  serial: string
  alertId: string
}

const AlertFeedbackModalContext =
  createContext<AlertFeedbackModalContextType | null>(null)

export const AlertFeedbackModalProvider: React.FC<{
  children: React.ReactNode
  displayedRoomName: string
}> = ({ children, displayedRoomName }) => {
  const [details, setAlertFeedbackDetails] = useState<AlertDetails | null>(null)

  return (
    <AlertFeedbackModalContext.Provider
      value={{
        details,
        open: !!details, // create a boolean to manage modal state
        setAlertFeedbackDetails,
        displayedRoomName,
      }}
    >
      {children}
    </AlertFeedbackModalContext.Provider>
  )
}

export const useAlertFeedbackModalContext = () => {
  const context = useContext(AlertFeedbackModalContext)
  if (!context) {
    throw new Error(
      'useAlertFeedbackModalContext must be used within a AlertFeedbackModalProvider',
    )
  }
  return context
}
