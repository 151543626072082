import * as React from 'react'
import { cn } from 'shared/utils/web/cn'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

export const defaultInputClassName =
  'border-input file:text-foreground placeholder:text-muted-foreground placeholder:text-sm placeholder:italic focus-visible:ring-ring h-10 bg-secondary text-secondary-foreground flex min-h-10 w-full rounded-md border px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50'

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(defaultInputClassName, className)}
        ref={ref}
        {...props}
      />
    )
  },
)
Input.displayName = 'Input'

export { Input }
