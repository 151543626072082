import { Trans } from '@lingui/react/macro'
import { t } from '@lingui/core/macro'
import { CircleCheck } from 'lucide-react'
import { Controller, useForm } from 'react-hook-form'
import { AlertComment } from 'shared/types/alert'
import { toast } from 'sonner'
import { AudioTextInput } from '../../components/AudioTextInput'
import { uploadSound } from '../../components/conversation/fileUploader'
import { FormError } from '../../components/conversation/FormError'
import { Button } from '../../components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '../../components/ui/Dialog'
import { set } from '../../firebaseMethods'
import { useAlertFeedbackModalContext } from './AlertFeedbackModalContext'

type FeedbackFormData = {
  comment: {
    text: string
    audioBlob: Blob | null
  }
}

async function commentAlert(
  date: string,
  serial: string,
  alertId: string,
  comment: AlertComment,
) {
  await set(`alerts/${date}/${serial}/${alertId}/ownership/comment`, comment)
}

export function AlertFeedbackModal() {
  const { details, open, setAlertFeedbackDetails } =
    useAlertFeedbackModalContext()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FeedbackFormData>({
    defaultValues: {
      comment: {
        audioBlob: null,
        text: '',
      },
    },
  })

  const onSubmit = async (data: FeedbackFormData) => {
    setAlertFeedbackDetails(null)

    toast(t`Merci pour votre retour !`, {
      className:
        'bg-background-dialog text-dialog flex gap-2 rounded-sm p-2 text-lg',
      icon: <CircleCheck className="mr-1" />,
    })
    if ((!data.comment.audioBlob && !data.comment.text) || !details) return
    const { date, serial, alertId } = details
    let comment: AlertComment | undefined = undefined

    if (data.comment.audioBlob) {
      const [audioCommentPath, audioCommentURL] = await uploadSound(
        data.comment.audioBlob,
      )
      if (!audioCommentURL) {
        return
      }

      comment = { audioCommentURL, audioCommentPath }
    } else if (data.comment.text) {
      comment = { comment: data.comment.text }
    }
    if (!comment) return

    await commentAlert(date, serial, alertId, comment)

    reset()
  }

  const onClose = () => {
    setAlertFeedbackDetails(null)
    reset()
  }
  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        if (open) {
          onClose()
        }
      }}
    >
      <DialogContent className="bg-background-dialog text-dialog w-[95%]">
        <DialogHeader>
          <DialogTitle className="text-xl">
            <Trans>Commenter cette alerte</Trans>
          </DialogTitle>
          <DialogDescription className="text-dialog text-md">
            <Trans>
              Contribuez à l'amélioration continue de l’Oreille Augmentée en
              faisant part de vos retours (texte ou vocal) sur cette alerte
            </Trans>
          </DialogDescription>
        </DialogHeader>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <Controller
            name="comment"
            control={control}
            rules={{
              validate: (value) => {
                if (
                  value.text &&
                  value.audioBlob === null &&
                  value.text.trim().length > 500
                ) {
                  return t`Le commentaire ne doit pas dépasser 500 caractères`
                }
                return (
                  (value.text && value.text.trim() !== '') ||
                  value.audioBlob instanceof Blob ||
                  t`Veuillez fournir un commentaire textuel ou vocal`
                )
              },
            }}
            render={({ field }) => (
              <AudioTextInput
                text={field.value.text}
                onTextChanged={(text) =>
                  field.onChange({ ...field.value, text })
                }
                audioBlob={field.value.audioBlob}
                onAudioRecorded={(blob) =>
                  field.onChange({ ...field.value, audioBlob: blob })
                }
                placeholder={t`Votre retour ...`}
                variant="textarea"
              />
            )}
          />

          {errors.comment && <FormError error={errors.comment.message} />}

          <div className="flex justify-between">
            <Button
              type="button"
              variant="outline"
              onClick={() => onClose()}
              size="lg"
            >
              <Trans>Annuler</Trans>
            </Button>
            <Button type="submit" variant="submit" size="lg">
              <Trans>Envoyer</Trans>
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}
