import { captureMessage } from '@sentry/react'
import { GoogleAuthProvider, signInWithRedirect } from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { lastUserEmailStorageKey } from 'shared/hooks/createUseAuth'
import { lastUserPasswordStorageKey } from '../../androidCallbacks'
import { Input } from '../../components/Input'
import { Button } from '../../components/ui/Button'
import { auth } from '../../firebase'
import logo from './oso.png'

export const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')

  const handleConnectWithGoogle = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault()
    signInWithRedirect(auth, new GoogleAuthProvider())
  }

  async function loginUser(email: string, password: string) {
    setError('')
    setSuccess('')
    try {
      await window.loginUser(email, password)
      setSuccess('Connexion réussie')
    } catch (_error) {
      setError('Erreur lors de la connexion')
    }
  }

  async function handleConnectWithEmailAndPassword(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) {
    event.preventDefault()
    await loginUser(email, password)
  }

  useEffect(
    () => {
      const lastUserEmail = localStorage.getItem(lastUserEmailStorageKey)
      const lastUserPassword = localStorage.getItem(lastUserPasswordStorageKey)

      const email = lastUserEmail ?? 'no email'
      captureMessage(
        `Login page: ${email}, ${lastUserPassword ? 'with' : 'no'} password`,
      )
      if (lastUserEmail && lastUserPassword) {
        // Log to see if this auto-login procedure is actually used
        if (!auth.currentUser)
          captureMessage(
            `Automatic re-login from local storage credentials for ${email}`,
          )
        loginUser(lastUserEmail, lastUserPassword)
      }
    },
    [], // Ran once on start
  )

  return (
    <form className="m-auto w-1/2 max-w-lg flex-1">
      <div className="flex flex-col space-y-2">
        <img
          src={logo}
          alt="logo"
          className="pointer-events-none my-10 w-1/2 self-center"
        />
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Mot de passe"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="mt-8" />
        <Button onClick={handleConnectWithEmailAndPassword}>Valider</Button>
        <span className="self-center">Ou</span>
        <Button type="button" onClick={handleConnectWithGoogle}>
          Se connecter avec Google
        </Button>
        {error && (
          <div className="bg-red-400 text-center text-white">{error}</div>
        )}
        {success && (
          <div className="bg-teal-400 text-center text-white">{success}</div>
        )}
      </div>
    </form>
  )
}
