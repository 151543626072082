import { Trans } from '@lingui/react/macro'
import { ChannelId } from 'common/types'
import { ArrowLeft } from 'lucide-react'
import React, { useContext, useState } from 'react'
import { Centered } from 'shared/components/Centered'
import { FacilityId, UserId } from 'shared/types/utils'
import { Deferred } from 'shared/utils/web/deferred'
import { USER_CLOSED_DIALOG } from '../../components/Dialog'
import { Button } from '../../components/ui/Button'
import { Contacts } from './Contacts'
import { Conversation } from './Conversation'
import { contactsContext } from './contactsProvider'

export type ConversationContext =
  | { userId: UserId; channelId: undefined }
  | { channelId: ChannelId; channelName: string }

export const Communication: React.FC<{
  deferred: Deferred<void>
  facilityId: FacilityId
}> = ({ deferred, facilityId }) => {
  const { loading, error, discussions, contacts } = useContext(contactsContext)

  // undefined when no conversation is displayed
  const [conversationContext, setConversationContext] =
    useState<ConversationContext>()

  return (
    <div className="bg-oso-background text-menu absolute inset-0 flex min-h-screen flex-col space-y-3 p-1">
      {loading && (
        <Centered>
          <Trans>Chargement...</Trans>
        </Centered>
      )}
      {error && (
        <Centered>
          <Trans>Erreur</Trans>
        </Centered>
      )}
      {conversationContext ? (
        <ConversationHeader
          onClose={() => setConversationContext(undefined)}
          title={
            conversationContext.channelId === undefined
              ? contacts[conversationContext.userId].name
              : conversationContext.channelName
          }
        >
          <Conversation
            conversationContext={conversationContext}
            facilityId={facilityId}
            conversationId={
              conversationContext.channelId === undefined
                ? discussions?.[conversationContext.userId]?.conversationId
                : conversationContext.channelId
            }
          />
        </ConversationHeader>
      ) : (
        <Contacts
          setConversationContext={setConversationContext}
          onClose={() => deferred.reject(USER_CLOSED_DIALOG)}
        />
      )}
    </div>
  )
}

type WrapperProps = {
  title: string
  onClose: (() => void) | undefined
}

const ConversationHeader: React.FC<React.PropsWithChildren<WrapperProps>> = ({
  children,
  onClose,
  title,
}) => {
  return (
    <>
      <div className="flex flex-row items-center gap-2">
        {onClose && (
          <Button size="icon" onClick={onClose} variant="secondary">
            <ArrowLeft className="size-6" />
          </Button>
        )}
        <div className="flex-1 text-2xl font-bold">{title}</div>
      </div>
      <div className="flex-1 overflow-hidden">{children}</div>
    </>
  )
}
