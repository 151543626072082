import { msg } from '@lingui/core/macro'
import { useLingui } from '@lingui/react/macro'
import { ArrowLeft } from 'lucide-react'
import { useCallback, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { removeTemporaryTheme, setTemporaryTheme } from '../../components/Theme'
import { Button } from '../../components/ui/Button'

const TECHNCIAN_PATHNAME = '/technician'

export const TECHNICIAN_ROUTES = {
  devices: { name: msg`Gestion des boîtiers`, path: '/technician/devices' },
  chat: { name: msg`Messagerie technicien`, path: '/technician/chat' },
}

const getTitleFromPathname = (pathname: string) => {
  switch (pathname) {
    case TECHNICIAN_ROUTES.chat.path:
      return TECHNICIAN_ROUTES.chat.name
    case TECHNICIAN_ROUTES.devices.path:
      return TECHNICIAN_ROUTES.devices.name
    default:
      return msg`Service Technique`
  }
}

export const TechnicianScreen = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { t } = useLingui()
  const onASBack = useCallback(() => {
    removeTemporaryTheme('tech')
    navigate('/live')
  }, [navigate])

  useEffect(() => {
    setTemporaryTheme('tech')
  }, [])

  const title = t(getTitleFromPathname(pathname))

  return (
    <div className="text-foreground flex h-full grow flex-col">
      <div className="bg-background sticky top-0 z-10 mb-2 flex flex-row items-center justify-start gap-2 p-2 shadow-md">
        <Button
          size="icon"
          onClick={() =>
            pathname === TECHNCIAN_PATHNAME
              ? onASBack()
              : navigate(TECHNCIAN_PATHNAME)
          }
          variant="secondary"
        >
          <ArrowLeft className="size-4" />
        </Button>
        {title}
      </div>
      <div className="flex h-full grow flex-col px-2">
        <Outlet />
      </div>
    </div>
  )
}
