import { DatabaseSchema, UserRelease } from 'common/databaseSchema'
import React, { useContext, useMemo, useState } from 'react'
import { Centered } from 'shared/components/Centered'
import { MergedType } from 'shared/hooks/createUseMergedFirebase'
import { collator } from 'shared/utils/web/collator'
import { Button } from '../../components/ui/Button'
import { facilitiesContext } from '../../contexts/FacilitiesProvider'
import { useMergedFirebase } from '../../hooks/useMergedFirebase'

function durationString(ms: number) {
  const minutes = ms / 60 / 1000
  if (minutes < 60) return `${Math.round(minutes)} min`
  const hours = minutes / 60
  if (hours < 48) return `${Math.round(hours)} h`
  const days = hours / 24
  return `${Math.round(days)} j`
}

function delaySince(timestamp: number | undefined) {
  if (timestamp === undefined) return <span />
  const now = Date.now()
  return (
    <span className="ml-1 text-sm">({durationString(now - timestamp)})</span>
  )
}

// Defined outside of component to get a constant reference
const refPathsMap = {
  users: 'users' as const,
  userReleases: 'userReleases' as const,
  versionHistory: 'versionHistory' as const,
}

const UserStatsReal: React.FC = () => {
  const { data, loading, error } =
    useMergedFirebase<MergedType<typeof refPathsMap, DatabaseSchema>>(
      refPathsMap,
    )

  const { facilities } = useContext(facilitiesContext)

  const sortedVersions = useMemo(
    () =>
      Object.entries(data.versionHistory ?? {})
        .sort(([_version1, ts1], [_version2, ts2]) => ts2 - ts1)
        .map(([version, _]) => version),
    [data.versionHistory],
  )

  const sortedUid = useMemo(
    () =>
      Object.entries(data.users ?? {})
        .sort(([_uid1, user1], [_uid2, user2]) =>
          collator.compare(user1?.email ?? '', user2?.email ?? ''),
        )
        .map(([uid, _user]) => uid),
    [data.users],
  )

  function webRelease(userRelease: UserRelease | undefined) {
    if (userRelease === undefined) return ''

    const version = (userRelease.web?.version ?? '').replace(
      `${import.meta.env.MODE}-`,
      '',
    )
    const index = sortedVersions.indexOf(version)

    if (index === -1) return version
    return index === 0 ? '✅' : `v-${index}`
  }

  const invalidUserReleases = useMemo(
    () =>
      Object.keys(data.userReleases ?? {}).filter(
        (uid) => (data.users ?? {})[uid] === undefined,
      ),
    [data.users, data.userReleases],
  )

  const latestAndroidVersion = Math.max(
    ...Object.values(data.userReleases ?? {}).map((userRelease) =>
      Number(userRelease.android?.version ?? '0'),
    ),
  )

  if (error) return <Centered>Erreur</Centered>
  if (loading) return <Centered>Chargement...</Centered>

  return (
    <div>
      <div className="font-bold">{sortedUid.length} utilisateurs</div>
      <table className="w-full">
        <thead>
          <tr>
            <th className="px-1 py-1"></th>
            <th className="px-4 py-1">Email</th>
            <th className="px-4 py-1">Établissement</th>
            <th className="px-4 py-1">Web</th>
            <th className="px-4 py-1">Android</th>
          </tr>
        </thead>

        <tbody>
          {sortedUid.map((uid) => {
            const user = data.users[uid]
            if (user === undefined) throw 'Impossible'
            return (
              <tr key={uid} className="even:bg-neutral-600">
                <td className="px-1">{user.role === 'admin' ? '✫' : ''}</td>
                <td className="px-2">
                  {(user.email ?? `[${uid}]`).replace('@oso-ai.com', '')}
                </td>
                <td className="px-2">
                  {user.role === 'AS'
                    ? (facilities[user.facilityId]?.name ?? '⚠️')
                    : ''}
                </td>
                <td className="px-2">
                  {webRelease(data.userReleases[uid])}
                  {delaySince(data.userReleases[uid]?.web?.timeStamp)}
                </td>
                <td className="px-2">
                  {data.userReleases[uid]?.android?.version ===
                  latestAndroidVersion
                    ? '✅'
                    : data.userReleases[uid]?.android?.version}
                  {delaySince(data.userReleases[uid]?.android?.timeStamp)}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {invalidUserReleases.length > 0 && (
        <>
          <div>Versions web sans user associé</div>
          {invalidUserReleases.map((uid) => (
            <div key={uid}>{uid}</div>
          ))}
        </>
      )}
    </div>
  )
}

export const UserStats: React.FC = () => {
  const [showUserStats, setShowUserStats] = useState(false)

  return (
    <div className="flex flex-col gap-3">
      <Button onClick={() => setShowUserStats(!showUserStats)}>
        {showUserStats
          ? 'Cacher les versions des utilisateurs'
          : 'Afficher les versions des utilisateurs'}
      </Button>

      {showUserStats && <UserStatsReal />}
    </div>
  )
}
