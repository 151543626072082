import { Trans } from '@lingui/react/macro'
import { useContext } from 'react'
import { FacilityDevice } from 'shared/types/live'
import { asFirebaseKey } from 'shared/utils/firebase'
import { Button } from '../../../components/ui/Button'
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../../../components/ui/Card'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '../../../components/ui/Drawer'
import { Skeleton } from '../../../components/ui/Skeleton'
import { useFirebase } from '../../../hooks/useFirebase'
import { RoomNamesContext } from '../../Live/RoomNamesContext'
import { ReportBugDialog } from './ReportBugDialog'

type DeviceCardProps = {
  serial: string
  device: FacilityDevice
  showOfflineOnly: boolean
}

const DeviceCardSkeleton = () => {
  return (
    <div className="bg-card/50 flex flex-row items-center gap-2 rounded-xl p-6 shadow">
      <div className="flex w-full flex-col gap-2">
        <Skeleton className="h-4 w-full" />
        <Skeleton className="h-4 w-full" />
      </div>
      <Skeleton className="size-8 rounded-full" />
    </div>
  )
}

export const DeviceCard = ({
  serial,
  device,
  showOfflineOnly,
}: DeviceCardProps) => {
  const roomState = useFirebase(`roomStates/${serial}`)

  const { roomNames } = useContext(RoomNamesContext)

  const { room, id } = device

  const roomName = roomNames[asFirebaseKey(room)] ?? ''

  if (roomState.loading) return <DeviceCardSkeleton />

  const isOffline = roomState.data?.isOffline === true

  if ((showOfflineOnly && isOffline === false) || roomState.data === null) {
    return null
  }

  const offlineColor = isOffline
    ? 'bg-offline text-offline-foreground'
    : 'bg-card text-card-foreground'

  return (
    <Drawer>
      <DrawerTrigger>
        <Card className={offlineColor}>
          <CardHeader className="flex flex-row items-center justify-between">
            <div className="flex min-w-0 flex-col items-start gap-2">
              <CardTitle className="flex w-full flex-row justify-between gap-2">
                <span className="truncate">{room}</span>
                {roomName && (
                  <span className="flex-1 truncate text-left font-bold">
                    {roomName}
                  </span>
                )}
              </CardTitle>
              <CardDescription>
                {id && <Trans>Numéro du boîtier : {id}</Trans>}
              </CardDescription>
            </div>
            {isOffline && (
              <span className="line-clamp-2 max-w-[100px] text-right leading-tight">
                <Trans>Hors ligne</Trans>
              </span>
            )}
          </CardHeader>
        </Card>
      </DrawerTrigger>
      <DrawerContent>
        <DrawerHeader className="flex flex-row items-center justify-between">
          <DrawerTitle>{room}</DrawerTitle>
          {roomName && (
            <div className="flex-1 truncate text-left">{roomName}</div>
          )}
        </DrawerHeader>

        <DrawerDescription>
          {id && <Trans>Numéro du boîtier : {id}</Trans>}
        </DrawerDescription>

        <div className="flex flex-col items-center justify-center gap-2">
          <ReportBugDialog serial={serial} room={room} id={id} />
        </div>
        <DrawerFooter>
          <DrawerClose asChild>
            <Button variant="outline" size="lg">
              <Trans>Fermer</Trans>
            </Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
