import { ALERT_NOTIFICATION_TTL } from 'common/alert'
import { DateTime } from 'luxon'
import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FirebaseKey } from 'shared/types/utils'
import { dateTimeFromISO, ms } from 'shared/utils/time'
import { Deferred } from 'shared/utils/web/deferred'
import { USER_CLOSED_DIALOG } from '../../components/Dialog'
import { AcknowledgeAlertDialog } from './AcknowledgeAlertDialog'

export const AlertContext = createContext<{
  onAlertReceived: (
    serial: string,
    alertId: FirebaseKey,
    alertDate: string,
    isEnded: boolean,
    room: string,
    roomName: string,
  ) => void
  notificationAlertId: FirebaseKey | undefined
  hasRecentAlert: () => boolean
}>({
  onAlertReceived: (
    _serial: string,
    _alertId: FirebaseKey,
    _alertDate: string,
    _isEnded: boolean,
    _room: string,
    _roomName: string,
  ) => {},
  notificationAlertId: undefined,
  hasRecentAlert: () => false,
})

type Alert = {
  alertId: FirebaseKey
  dateTime: DateTime
  room: string
  roomName: string
  isEnded: boolean
}

// Should match alert notification TTL
const CLOSE_DELAY = ALERT_NOTIFICATION_TTL

const MERGE_BATCHES_DURATION = ms(1.0, 'second')

const HAS_RECENT_ALERT = ms(10, 'minute')

/*
Centralizes the display of an AcknowledgeAlertDialog popup when
a new alert arrives.
Alerts are emitted using onAlertReceived by a one-off useEffect,
and only the most recent one is displayed in case there are several.
A timer hides the popup after a set delay.
*/
export const AlertProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  // New alerts are sent ONCE from Room, and only the most recent one is displayed
  const alert = useRef<Alert>()
  const lastAlertTime = useRef<number>()
  const openDialogTimeoutRef = useRef<NodeJS.Timeout>()
  const closeDialogTimeoutRef = useRef<NodeJS.Timeout>()

  const [dialogDeferred, setDialogDeferred] = useState<Deferred<void> | null>(
    null,
  )

  const hasRecentAlert = useCallback(() => {
    if (!lastAlertTime.current) return false
    const timeSinceLastAlert = Date.now() - lastAlertTime.current
    return timeSinceLastAlert < HAS_RECENT_ALERT
  }, [])

  function closeDialog() {
    alert.current = undefined
    setDialogDeferred(null)
  }

  const onAlertReceived = useCallback(
    (
      serial: string,
      alertId: FirebaseKey,
      alertDate: string,
      isEnded: boolean,
      room: string,
      roomName: string,
    ) => {
      if (window.Android?.onAlertDisplayed)
        window.Android.onAlertDisplayed(room, serial)

      const dateTime = dateTimeFromISO(alertDate)

      // May happen when phone syncs after getting back online
      const alertIsTooOld = dateTime.diffNow().as('milliseconds') < -CLOSE_DELAY
      const alertIsNewer = !alert.current || dateTime >= alert.current.dateTime
      if (alertIsTooOld || !alertIsNewer) return // Skip acknowledge dialog

      // Update most recent alert
      alert.current = { alertId, dateTime, room, roomName, isEnded }
      lastAlertTime.current = Date.now()

      // A short grace period to gather all recently validated alerts in
      // case device gets back online and sends them all at once.
      // Keep updating the most recent alert during this period.
      if (!openDialogTimeoutRef.current) {
        openDialogTimeoutRef.current = setTimeout(async () => {
          openDialogTimeoutRef.current = undefined
          if (!alert.current?.isEnded) await showAcknowledgeDialog()
        }, MERGE_BATCHES_DURATION)
      }

      async function showAcknowledgeDialog() {
        const deferred = new Deferred<void>()
        setDialogDeferred(deferred)
        try {
          await deferred.promise
        } catch (error) {
          if (error !== USER_CLOSED_DIALOG) {
            throw error
          }
        } finally {
          // Acknowledge even when tapping outside to dismiss
          // Immediately stop phone notification on this device
          if (window.Android?.onAlertAcknowledged)
            window.Android.onAlertAcknowledged(room, serial)

          closeDialog()
        }
      }
    },
    // Keep this empty, otherwise reference would change, triggering a
    // new call from Room, resulting in an infinite loop.
    [],
  )

  useEffect(() => {
    if (closeDialogTimeoutRef.current)
      clearTimeout(closeDialogTimeoutRef.current)

    if (dialogDeferred && alert.current) {
      // Note diffNow is a negative value
      const delay =
        CLOSE_DELAY + alert.current.dateTime.diffNow().as('milliseconds')

      closeDialogTimeoutRef.current = setTimeout(closeDialog, delay)
    } else {
      closeDialogTimeoutRef.current = undefined
    }
  }, [dialogDeferred])

  return (
    <AlertContext.Provider
      value={{
        onAlertReceived,
        notificationAlertId: alert.current?.alertId,
        hasRecentAlert,
      }}
    >
      {dialogDeferred && alert.current && (
        <AcknowledgeAlertDialog
          room={alert.current.room}
          roomName={alert.current.roomName}
          deferred={dialogDeferred}
        />
      )}
      {children}
    </AlertContext.Provider>
  )
}
