import { Trans } from '@lingui/react/macro'
import { Deferred } from 'shared/utils/web/deferred'
import {
  Dialog,
  USER_BACK_BUTTON,
  USER_CLOSED_DIALOG,
} from '../../components/Dialog'
import { Button } from '../../components/ui/Button'
import Arrow from '../../icons/arrow.svg?react'

export const FAQDialog: React.FC<{
  deferred: Deferred<void>
}> = ({ deferred }) => {
  return (
    <Dialog onClose={() => deferred.reject(USER_CLOSED_DIALOG)}>
      <div
        className="flex cursor-pointer flex-row items-center space-x-2"
        onClick={() => deferred.reject(USER_BACK_BUTTON)}
      >
        <Arrow className="w-4" fill="currentColor" />
        <span className="flex-1">
          <Trans>Foire aux Questions</Trans>
        </span>
      </div>
      <div className="flex flex-col space-y-3 overflow-y-auto text-lg">
        <p>
          <Trans>
            L'objectif de la solution développée par OSO est d' améliorer la
            qualité de vie au travail du personnel soignant, ainsi que la
            qualité de vie et la sécurité des résidents.
          </Trans>
        </p>
        <p>
          <Trans>
            Nous mettons tout en œuvre dans ce but, en respectant la vie privée
            de chacun. Notre solution est à vos côtés, pour vous apporter plus
            de sérénité et un gain de temps dans votre organisation.
          </Trans>
        </p>
        <div className="font-bold">
          <Trans>Comment ça marche&nbsp;?</Trans>
        </div>
        <Trans>
          Le boîtier situé dans chaque chambre est équipé d'un micro. Les sons
          sont analysés de façon automatique, en filtrant uniquement ceux qui
          sont jugés inquiétants (vomissements, appels à l’aide, chocs, bips des
          machines…). Des alertes sont alors envoyées sur les téléphones
          portables pour prévenir les soignants.
        </Trans>
        <div className="font-bold">
          <Trans>
            Est-ce que je peux écouter une chambre à distance&nbsp;?
          </Trans>
        </div>
        <Trans>
          Non. Les sons sont analysés en direct, et c'est seulement en cas
          d'alerte qu'ils sont conservés, et que 10 secondes peuvent être
          écoutés à distance pour juger de la gravité de l'évènement.
        </Trans>
        <div className="font-bold">
          <Trans>Est-ce que je suis enregistré·e&nbsp;?</Trans>
        </div>
        <Trans>
          Non. Le boitier continue à fonctionner lorsqu'un soignant ou un membre
          de la famille est détecté dans la pièce.
        </Trans>
        <p>
          <Trans>
            Néanmoins, l'analyse des sons est alors mise en pause, et les
            conversations ne sont pas analysées, pour respecter l'intimité.
            Aucune alerte n'est déclenchée durant cette période.
          </Trans>
        </p>
        <div className="font-bold">
          <Trans>Qu’est-ce que le site web de suivi&nbsp;?</Trans>
        </div>
        <Trans>
          Ce site web sécurisé permet aux équipes de suivre l'activité dans
          l'établissement. Il regroupe sur une même interface l’ensemble des
          alertes qui ont été levées, et permet d'écouter le son qui en est à
          l'origine, exactement comme sur les téléphones. Il affiche également
          pour chaque résident son activité moyenne (sommeil, TV, plainte…) sur
          la journée. Un historique de trois semaines est disponible.
        </Trans>
        <div className="font-bold">
          <Trans>Et si j’ai d’autre questions&nbsp;?</Trans>
        </div>
        <Trans>
          La messagerie (disponible dans cette application) permet de discuter
          directement avec nos équipes. N'hésitez pas à nous faire remonter
          toutes vos interrogations ou vos remarques.
        </Trans>
        <p className="text-right">
          <Trans>L'équipe</Trans> OSO
        </p>
      </div>
      <div className="flex flex-row">
        <Button
          variant="outline"
          size="full"
          onClick={() => deferred.reject(USER_CLOSED_DIALOG)}
        >
          <Trans>Fermer</Trans>
        </Button>
      </div>
    </Dialog>
  )
}
