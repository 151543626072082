import { MessageDescriptor } from '@lingui/core'
import { msg } from '@lingui/core/macro'
import { AlertType, BeepType, Category } from './alert'

// 🌪️ i18n warning : update accordinly in shared/src/types/alert.ts 🌪️
export const CATEGORY_DISPLAY_TRANSLATE: Record<Category, MessageDescriptor> = {
  emergency: msg`Urgence`,
  comfort: msg`Confort`,
}

// 🌪️ i18n warning : update accordinly in shared/src/types/alert.ts 🌪️
export const ALERT_TYPE_TRANSLATE: Record<AlertType, MessageDescriptor> = {
  AS_CALL: msg`Appel`,
  BEEP: msg`Bip machine`,
  BOOM: msg`Choc inquiétant`,
  BREATHING: msg`Gêne respiratoire`,
  BREATHING_ASTHMA: msg`Respiration sifflante`,
  BREATHING_EDEMA: msg`Détresse respiratoire`,
  BREATHING_JANUS: msg`Problème respiratoire (JANUS)`,
  BREATHLESSNESS: msg`Essoufflement prolongé`,
  CANCEL_ROOM_EXIT: msg`Non sortie de chambre`,
  CLICKER: msg`Appel bouton`,
  CRY: msg`Pleurs`,
  DRUM: msg`Tambourinage`,
  FALL_JANUS: msg`Choc inquiétant (JANUS)`,
  PUKE: msg`Vomissement`,
  PUKE_JANUS: msg`Vomissement (JANUS)`,
  ROOM_EXIT: msg`Sortie de chambre`,
  SPEECH: msg`Appel`,
  TEETH_GRINDING: msg`Grincement de dents`,
  WAKEUP: msg`Réveil`,
  WHINE: msg`Gémissement`,
  YELL: msg`Cri`,
}

// Définir BEEP_TYPE_TRANSLATE
export const BEEP_TYPE_TRANSLATE: Record<BeepType, MessageDescriptor> = {
  pousse_seringue_vide: msg`pousse seringue vide`,
  pousse_seringue_presque_vide: msg`pousse seringue presque vide`,
  pompe: msg`pompe`,
  matelas: msg`matelas`,
  oxygene: msg`oxygène`,
  dinamap: msg`dinamap`,
  girafe: msg`girafe`,
  pompe_erreur_occlusion: msg`pompe erreur occlusion`,
  electrocardiogramme: msg`électrocardiogramme`,
  extracteur_o2: msg`extracteur o2`,
  detecteur_de_fumee: msg`détecteur de fumée`,
  detecteur_de_mouvement: msg`détecteur de mouvement`,
  vni: msg`vni`,
  dextro: msg`dextro`,
}
