import { ADMIN_USER_ID, ConversationPath, osoChannelId } from 'common/channels'
import { AlertDetails, Bubble } from 'common/types'
import { MessageCircle } from 'lucide-react'
import { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AlertComment } from 'shared/types/alert'
import { AudioTextInput } from '../../../components/AudioTextInput'
import { FormError } from '../../../components/conversation/FormError'
import { AlertQuote } from '../../../components/conversation/Quote'
import { Button } from '../../../components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../components/ui/Dialog'
import { useMessagePoster } from '../../../hooks/useMessagePoster'
import { saveFacilityChannelBubble } from '../../Live/GroupComposer'
import { contactsContext } from '../../Live/contactsProvider'

export const AnswerCommentedAlertDialog = ({
  comment,
  details,
}: {
  comment: AlertComment
  details: AlertDetails
}) => {
  const { facilityId } = useParams<{ facilityId: string }>()
  const [open, setOpen] = useState<boolean>(false)

  const { contacts } = useContext(contactsContext)

  const saveBubble = async (bubble: Bubble): Promise<ConversationPath[]> => {
    const quotedBubble = { ...bubble, quote: { ...comment, details } }
    handleClose()

    if (!facilityId) return []

    return Promise.all([
      saveFacilityChannelBubble(
        osoChannelId,
        contacts,
        facilityId,
        quotedBubble,
      ),
    ])
  }

  const { text, setText, audioBlob, setAudioBlob, error, sendMessage } =
    useMessagePoster(ADMIN_USER_ID, saveBubble)

  if (!facilityId) return null

  const handleClose = () => setOpen(false)

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>
          <MessageCircle className="mr-2 size-4" />
          Répondre
        </Button>
      </DialogTrigger>
      <DialogContent className="bg-background text-foreground text-dialog w-1/2 max-w-[90vw]">
        <DialogHeader>
          <DialogTitle
            className="text-foreground flex cursor-pointer flex-row items-center space-x-2 text-lg"
            onClick={handleClose}
          >
            Répondre au commentaire de l'alerte
          </DialogTitle>
        </DialogHeader>
        <DialogDescription className="hidden" />
        <div className="text-foreground flex flex-col gap-2">
          <div className="rounded-md bg-gray-50">
            <AlertQuote quote={{ ...comment, details }} details={details} />
          </div>
        </div>
        <form
          method="post"
          onSubmit={sendMessage}
          className="flex flex-col gap-2"
        >
          <FormError error={error} />
          <AudioTextInput
            text={text}
            onTextChanged={setText}
            audioBlob={audioBlob}
            onAudioRecorded={setAudioBlob}
            placeholder="Votre réponse ..."
            variant="textarea"
            autoFocus
          />
          <DialogFooter>
            <Button onClick={handleClose} variant="secondary" type="button">
              Annuler
            </Button>
            <Button variant="submit" type="submit">
              Répondre
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}
