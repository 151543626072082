import { Trans } from '@lingui/react/macro'
import { t } from '@lingui/core/macro'
import { LoaderCircle, Mic, Square, Trash } from 'lucide-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useReactMediaRecorder } from 'react-media-recorder-2'
import { ms } from 'shared/utils/time'
import { AudioPlayer } from './AudioPlayer'
import { FormError } from './conversation/FormError'
import { Button } from './ui/Button'
import { Input } from './ui/Input'
import { Textarea } from './ui/Textarea'

const MIN_IN_SEC = 3
const MAX_AUDIO_DURATION = ms(40, 'seconds')
const MIN_AUDIO_DURATION = ms(MIN_IN_SEC, 'seconds')

interface AudioTextInputProps {
  text: string
  onTextChanged: (text: string) => void
  audioBlob: Blob | null
  onAudioRecorded: (blob: Blob | null) => void
  placeholder: string
  autoFocus?: boolean
  variant: 'input' | 'textarea'
}

type TextInputProps = {
  variant: 'input' | 'textarea'
  commonProps: {
    value: string
    placeholder: string
    autoFocus: boolean | undefined
    className: string
    onChange: (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void
  }
}

const TextInput: React.FC<TextInputProps> = ({ commonProps, variant }) => {
  return variant === 'input' ? (
    <Input {...commonProps} />
  ) : (
    <Textarea {...commonProps} />
  )
}

export const AudioTextInput: React.FC<AudioTextInputProps> = ({
  text,
  onTextChanged,
  audioBlob,
  onAudioRecorded,
  placeholder,
  autoFocus,
  variant,
}) => {
  const recordingTimerRef = useRef<NodeJS.Timeout | null>(null)
  const startRef = useRef<number | null>(null)

  const [error, setError] = useState<string>('')

  const { status, startRecording, stopRecording, clearBlobUrl, mediaBlobUrl } =
    useReactMediaRecorder({
      audio: true,
      onStart: () => {
        startRef.current = Date.now()
      },
      onStop: (_blobUrl: string, blob: Blob) => {
        if (startRef.current) {
          const duration = Date.now() - startRef.current
          startRef.current = null

          if (duration >= MIN_AUDIO_DURATION) {
            onAudioRecorded(blob)
          } else {
            setError(t`Enregistrement trop court (${MIN_IN_SEC}s minimum)`)
            clearBlobUrl()
          }
        }
      },
    })

  useEffect(() => {
    return () => {
      if (recordingTimerRef.current) {
        clearTimeout(recordingTimerRef.current)
      }
    }
  }, [])

  const stop = useCallback(() => {
    stopRecording()
    if (recordingTimerRef.current) {
      clearTimeout(recordingTimerRef.current)
      recordingTimerRef.current = null
    }
  }, [stopRecording])

  /**
   * Sur mobile :
   * - `onMouseDown` et `onMouseUp` ne fonctionnent pas de manière fiable, car les interactions tactiles
   *   déclenchent d'autres événements (`touchstart`, `touchend`, `click`), et il peut y avoir
   *   des délais ou interférences avec le système.
   * - `touchStart` est utilisé pour garantir une réactivité immédiate et éviter les délais du `click`.
   * - `touchEnd` est ajouté globalement (`document.addEventListener('touchend', stop)`) et sur la div de blur
   *   pour s'assurer que l'enregistrement s'arrête même si l'utilisateur relâche le doigt en dehors du bouton.

   * Sur desktop :
   * - `onMouseDown` est utilisé pour démarrer l'enregistrement.
   * - `onMouseUp` pourrait logiquement être utilisé pour l'arrêter, mais on ne l'emploie pas
   *   car l'utilisateur pourrait bouger la souris hors du bouton avant de relâcher, ce qui
   *   empêcherait l'arrêt correct de l'enregistrement.
   * - `onClick` sur la div de blur permet de stopper l'enregistrement
  */

  const handleStartRecording = useCallback(
    (event: React.MouseEvent | React.TouchEvent) => {
      event.preventDefault() //  Évite la conversion en `click`
      setError('')
      if (status === 'recording') {
        return stop()
      }

      startRecording()

      recordingTimerRef.current = setTimeout(() => {
        stop()
      }, MAX_AUDIO_DURATION)
    },
    [startRecording, status, stop],
  )

  const handleTextChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setError('')
    onTextChanged(event.target.value)
  }

  const clearAudio = () => {
    onAudioRecorded(null)
    clearBlobUrl()
  }

  useEffect(() => {
    document.addEventListener('touchend', stop)
    return () => document.removeEventListener('touchend', stop)
  }, [stop])

  const isMobile = 'ontouchstart' in window || navigator.maxTouchPoints > 0

  return (
    <div
      className="flex w-full flex-col"
      onContextMenu={(e) => e.preventDefault()}
    >
      {(status === 'recording' || status === 'acquiring_media') && (
        <div
          {...(isMobile
            ? { onTouchEnd: stop }
            : {
                onClick: stop,
              })}
          className="text-primary fixed inset-0 z-50 flex items-center justify-center bg-white/30 font-bold backdrop-blur-sm"
        >
          <div>
            {status === 'acquiring_media' && (
              <div className="flex flex-col items-center gap-2 text-pretty p-10 text-center">
                <LoaderCircle className="size-24 animate-spin" />
                <Trans>Chargement...</Trans>
              </div>
            )}
            {status === 'recording' && (
              <div className="flex flex-col items-center gap-2 text-pretty p-10 text-center">
                <RecordingPulse />
                <Trans>Enregistrement vocal en cours...</Trans>
              </div>
            )}
          </div>
        </div>
      )}
      <FormError error={error} />
      <div className="flex w-full items-end gap-2">
        <div className="flex h-full grow flex-row items-center justify-center">
          {audioBlob && mediaBlobUrl ? (
            <AudioPlayer
              url={mediaBlobUrl}
              buttons={
                <Button
                  size="icon"
                  onClick={clearAudio}
                  variant="destructive"
                  type="button"
                >
                  <Trash className="size-4" />
                </Button>
              }
            />
          ) : (
            <TextInput
              variant={variant}
              commonProps={{
                value: text,
                placeholder,
                autoFocus,
                className: 'px-1 text-md',
                onChange: handleTextChange,
              }}
            />
          )}
        </div>
        <Button
          className="min-w-10"
          disabled={status === 'stopping'}
          size="icon"
          variant={status === 'recording' ? 'destructive' : 'secondary'}
          type="button"
          {...(isMobile
            ? { onTouchStart: handleStartRecording }
            : {
                onContextMenu: (e) => e.preventDefault(), // avoid open menu on long click on desktop
                onMouseDown: handleStartRecording,
              })}
        >
          {status === 'recording' ? (
            <Square className="size-4" />
          ) : (
            <Mic className="size-4" />
          )}
          <span className="sr-only">
            {status === 'recording'
              ? t`Arrêter l'enregistrement`
              : t`Démarrer l'enregistrement`}
          </span>
        </Button>
      </div>
    </div>
  )
}

const RecordingPulse = () => {
  return (
    <span className="relative flex size-24 items-center justify-center">
      <span className="bg-destructive absolute inline-flex h-full w-full animate-ping rounded-full opacity-75"></span>
      <span className="bg-destructive relative flex inline-flex size-24 items-center justify-center rounded-full">
        <Mic className="size-12 text-white" />
      </span>
    </span>
  )
}
