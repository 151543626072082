import { useLingui } from '@lingui/react/macro'
import { ArrowLeft, MessageCircle, SquareActivity } from 'lucide-react'
import { useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { removeTemporaryTheme } from '../../components/Theme'
import { Card, CardContent } from '../../components/ui/Card'
import { TECHNICIAN_ROUTES } from './TechnicianLayout'

export const MenuScreen = () => {
  const navigate = useNavigate()
  const { t } = useLingui()
  const onASBack = useCallback(() => {
    removeTemporaryTheme('tech')
    navigate('/live')
  }, [navigate])

  return (
    <div className="flex h-full w-full flex-col gap-4">
      <Link to="/technician/devices">
        <MenuCard
          icon={<SquareActivity className="size-16" />}
          title={t(TECHNICIAN_ROUTES.devices.name)}
        />
      </Link>
      <Link to="/technician/chat">
        <MenuCard
          icon={<MessageCircle className="size-16" />}
          title={t(TECHNICIAN_ROUTES.chat.name)}
        />
      </Link>
      <div onClick={() => onASBack()}>
        <MenuCard
          icon={<ArrowLeft className="size-16" />}
          title={t`Retour à la page "Alertes"`}
        />
      </div>
    </div>
  )
}

type MenuCardProps = {
  icon: React.ReactNode
  title: string
}

const MenuCard = ({ icon, title }: MenuCardProps) => {
  return (
    <Card className="transition-shadow hover:shadow-lg">
      <CardContent className="flex flex-row items-center justify-start gap-4 p-4">
        {icon}
        <div>
          <h2 className="mt-2 text-lg font-semibold">{title}</h2>
        </div>
      </CardContent>
    </Card>
  )
}
