import React, { ChangeEvent, useEffect, useState } from 'react'
import { Deferred } from 'shared/utils/web/deferred'
import { Dialog, USER_CLOSED_DIALOG } from '../../components/Dialog'
import { Button } from '../../components/ui/Button'
import { push } from '../../firebaseMethods'
import { useFirebase } from '../../hooks/useFirebase'

const MAX_COMMENT_LENGTH = 25

export const LeaveCommentDialog: React.FC<{
  initialComment: string | undefined
  deferred: Deferred<string>
}> = ({ initialComment, deferred }) => {
  const [text, setText] = useState<string>('')
  const [selectedComment, setSelectedComment] = useState<string | undefined>(
    initialComment,
  )
  const [allowCommentCreation, setAllowCommentCreation] =
    useState<boolean>(false)

  const { data: evaluationComments, loading } =
    useFirebase('evaluationComments')

  const normalizeText = (text: string) => {
    return text
      .trim()
      .replace(/-/g, '_')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Remove accents
      .replace(/[^\w\s']/g, '') // Remove punctuation
      .replace(/ /g, '_')
      .toLowerCase()
  }

  useEffect(() => {
    const normalizedComment = normalizeText(text)
    if (text.trim().length === 0) {
      setAllowCommentCreation(false)
      return
    }

    if (
      Object.values(evaluationComments || {}).some(
        (existingComment) =>
          normalizeText(existingComment) === normalizedComment,
      )
    ) {
      setAllowCommentCreation(false)
      return
    }

    setAllowCommentCreation(true)
  }, [text, evaluationComments])

  const saveNewComment = (comment: string) => {
    push('evaluationComments', comment)
  }

  return (
    <Dialog onClose={() => deferred.reject(USER_CLOSED_DIALOG)}>
      Commentaire sur cette alerte
      {selectedComment === undefined || selectedComment.trim().length === 0 ? (
        <>
          <textarea
            autoFocus
            rows={4}
            value={text}
            className="border border-black bg-transparent px-2 py-1 focus:outline-none"
            placeholder={`Rechercher un commentaire existant ou en créer un nouveau (max. ${MAX_COMMENT_LENGTH} caractères)`}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setText(e.currentTarget.value)
            }
            maxLength={MAX_COMMENT_LENGTH}
          />
          <div className="flex max-h-96 flex-col gap-2 overflow-auto">
            {allowCommentCreation && (
              <Button
                className="my-4"
                onClick={() => {
                  saveNewComment(text)
                  setSelectedComment(text)
                }}
              >
                {`Créer : "${text}"`}
              </Button>
            )}
            {loading ? (
              <span className="text-center">Chargement...</span>
            ) : (
              Object.values(evaluationComments || {})
                .sort((existingCommentA, existingCommentB) =>
                  existingCommentA.localeCompare(existingCommentB),
                )
                .filter((existingComment) =>
                  text.trim().length !== 0
                    ? normalizeText(existingComment).includes(
                        normalizeText(text),
                      )
                    : true,
                )
                .map((existingComment) => (
                  <Button
                    key={existingComment}
                    onClick={() => setSelectedComment(existingComment)}
                  >
                    {existingComment}
                  </Button>
                ))
            )}
          </div>
        </>
      ) : (
        <>
          <span className="p-8 text-center font-bold">{selectedComment}</span>
          <Button
            onClick={() => {
              setText('')
              setSelectedComment(undefined)
            }}
          >
            Effacer
          </Button>
        </>
      )}
      <div className="flex flex-row gap-3">
        <Button
          variant="outline"
          size="lg"
          onClick={() => deferred.reject(USER_CLOSED_DIALOG)}
        >
          Annuler
        </Button>
        <Button
          size="lg"
          disabled={text.trim().length !== 0 && !selectedComment}
          onClick={() =>
            deferred.resolve(selectedComment ? selectedComment : '')
          }
        >
          Valider
        </Button>
      </div>
    </Dialog>
  )
}
