import { Trans } from '@lingui/react/macro'
import { t } from '@lingui/core/macro'
import { DialogDescription } from '@radix-ui/react-dialog'
import { techChannelId } from 'common/channels'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormError } from '../../components/conversation/FormError'
import { Button } from '../../components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../components/ui/Dialog'
import { Input } from '../../components/ui/Input'
import { contactsContext } from './contactsProvider'

export const AccessTechnicianDialog = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [code, setCode] = useState<string>('')
  const [error, setError] = useState<string>('')
  const navigate = useNavigate()

  const { discussions } = useContext(contactsContext)

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (code === '050') {
      navigate('/technician')
    } else {
      setCode('')
      setError(t`Code incorrect`)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setError('')
    setCode('')
  }

  useEffect(() => {
    if (code) setError('')
  }, [code])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <div className="flex flex-row items-center space-x-2">
          <span>●</span>
          <span className={`cursor-pointer underline`}>
            <Trans>Accès technicien</Trans>
          </span>
          <span>🔒</span>
          <>
            {discussions &&
              discussions[techChannelId] &&
              discussions[techChannelId]?.unreadCount > 0 && (
                <div className="bg-background-notification h-4 w-4 rounded-full" />
              )}
          </>
        </div>
      </DialogTrigger>
      <DialogContent className="bg-background-dialog text-dialog max-w-[90vw]">
        <DialogHeader>
          <DialogTitle>
            <Trans>Accès technicien</Trans>
          </DialogTitle>
          <DialogDescription>
            <Trans>
              L’accès à cette interface est restreint. Veuillez entrer le code
              d’accès.
            </Trans>
          </DialogDescription>
          <FormError error={error} />
          <form
            onSubmit={onSubmit}
            className="flex flex-col items-center gap-2"
          >
            <Input
              type="password"
              pattern="[0-9]*"
              inputMode="numeric"
              onChange={(e) => setCode(e.target.value)}
              value={code}
            />

            <div className="flex w-full flex-row justify-between">
              <Button onClick={handleClose} variant="outline" type="button">
                <Trans>Retour</Trans>
              </Button>
              <Button type="submit" variant="submit">
                <Trans>Valider</Trans>
              </Button>
            </div>
          </form>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}
