import { Trans } from '@lingui/react/macro'
import { Image } from 'lucide-react'
import React, { useEffect, useRef } from 'react'
import { Button } from './ui/Button'

interface FileInputProps {
  onFileSelected: (file: File) => void
  file: File | null
}

export const FileInput: React.FC<FileInputProps> = ({
  onFileSelected,
  file,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      onFileSelected(e.target.files[0])
    }
  }

  // clear the fileInput
  useEffect(() => {
    if (file === null && fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }, [file])

  return (
    <>
      <Button
        size="icon"
        variant="secondary"
        type="button"
        onClick={() => fileInputRef.current?.click()}
      >
        <Image className="size-4" />
        <span className="sr-only">
          <Trans>Téléverser une image</Trans>
        </span>
      </Button>

      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept="image/*"
        className="hidden"
      />
    </>
  )
}
