// Callback function on the global window object, to be called from the Android WebView
import { captureMessage } from '@sentry/react'
import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { lastUserEmailStorageKey } from 'shared/hooks/createUseAuth'
import { onError } from 'shared/utils/web/error'
import { auth } from './firebase'

export const lastUserPasswordStorageKey = `lastUserPassword`

window.loginUser = async function (email: string, password: string) {
  try {
    await signInWithEmailAndPassword(auth, email, password)

    // Questionnable behavior, so that the web app can auto login back
    // if auth is lost for any reason. Adding logs to measure usefulness
    localStorage.setItem(lastUserEmailStorageKey, email)
    localStorage.setItem(lastUserPasswordStorageKey, password)
  } catch (error) {
    captureMessage(`Unable to login for ${email}`)
    onError(error)
    throw error
  }
}

window.logoutUser = function () {
  signOut(auth)
}
