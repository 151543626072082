import React, { ChangeEvent, useState } from 'react'
import { Deferred } from 'shared/utils/web/deferred'
import { Dialog, USER_CLOSED_DIALOG } from '../../components/Dialog'
import { Button } from '../../components/ui/Button'

export const RoomCommentDialog: React.FC<{
  room: string
  initialComment: string
  deferred: Deferred<string>
}> = ({ room, initialComment, deferred }) => {
  const [comment, setComment] = useState(initialComment)
  return (
    <Dialog onClose={() => deferred.reject(USER_CLOSED_DIALOG)}>
      Commentaire sur la chambre {room}
      <textarea
        className="border border-black bg-transparent px-2 py-1 focus:outline-none"
        autoFocus
        rows={4}
        value={comment}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
          setComment(e.currentTarget.value)
        }
      />
      <div className="flex flex-row gap-4 self-center">
        <Button
          variant="secondary"
          onClick={() => deferred.reject(USER_CLOSED_DIALOG)}
        >
          Annuler
        </Button>
        <Button onClick={() => deferred.resolve(comment.trim())}>
          Valider
        </Button>
      </div>
    </Dialog>
  )
}
