import { t } from '@lingui/core/macro'
import {
  Alert,
  BEEP_TYPE_MAPPING,
  isBeepAlert,
  isOldBeepAlert,
  isOldVocalAlert,
  isVocalAlert,
} from 'shared/types/alert'
import {
  ALERT_TYPE_TRANSLATE,
  BEEP_TYPE_TRANSLATE,
} from 'shared/types/alert.i18n'

// 🌪️ i18n warning : update accordingly in live/src/common/alert.ts 🌪️
export function translateAlertDisplayText(alert: Alert) {
  if (isBeepAlert(alert)) {
    const text = isOldBeepAlert(alert)
      ? alert.text.replace(/_/g, ' ')
      : [
          ...new Set(
            alert.beepType.map((txt) =>
              t(BEEP_TYPE_TRANSLATE[BEEP_TYPE_MAPPING[txt]]),
            ),
          ),
        ].join(', ')
    return t`Bip ${text}`
  } // old version: text, new version translate text

  if (isVocalAlert(alert)) {
    if (isOldVocalAlert(alert)) {
      const filteredKeywords = alert.keywords.filter(
        (keyword) => keyword.toLowerCase() !== 'autre',
      )
      if (filteredKeywords.length > 0) {
        return [...new Set(filteredKeywords)]
          .map((keyword) => `"${keyword}"`)
          .join(', ')
      }
    } else {
      const filteredKeywords = alert.rawKeywords.filter(
        (keyword) => keyword.length > 0,
      )
      if (filteredKeywords.length > 0) {
        return [...new Set(filteredKeywords)]
          .map((keyword) => `"${keyword}"`)
          .join(', ')
      }
    } // new version, use rawKeywords
  }

  return t(ALERT_TYPE_TRANSLATE[alert.type])
}
