import React from 'react'

interface FormErrorProps {
  error: string | undefined
}

export const FormError: React.FC<FormErrorProps> = ({ error }) => {
  if (!error) return null

  return (
    <div className="text-destructive animate-[jiggle_0.3s_ease-in-out] text-sm">
      {error}
    </div>
  )
}
