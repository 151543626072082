import { automaticUid } from 'common/types'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import {
  SerialAlerts,
  alertTypeString,
  isAudioComment,
  isTextComment,
} from 'shared/types/alert'
import { dateTimeFromISO } from 'shared/utils/time'
import { siteUrl } from 'shared/utils/url'
import { Button } from '../../components/ui/Button'
import { get } from '../../firebaseMethods'
import { DateRange } from './Exports'

type RawCsvDatum = [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
]

type AutovalidationCsvDatum = [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
]

type AlertsHierarchy = Record<string /* date */, SerialAlerts>

type ExportType = 'csv' | 'json' | 'validated'

export const AlertsExport = ({ startDate, endDate }: DateRange) => {
  const [alertsData, setAlertsData] = useState<AlertsHierarchy | null>(null)
  const [rawCsvData, setRawCsvData] = useState<RawCsvDatum[] | null>(null)
  const [autoValidationCsvData, setAutoValidationCsvData] = useState<
    AutovalidationCsvDatum[] | null
  >(null)

  useEffect(() => {
    async function computeData() {
      let startDatetime = dateTimeFromISO(startDate).startOf('day')

      const endDatetime = dateTimeFromISO(endDate).endOf('day')

      const isoDates = []

      while (startDatetime < endDatetime) {
        isoDates.push(startDatetime.toISODate())
        startDatetime = startDatetime.plus({ days: 1 })
      }

      const devices = (await get('devices')) || {}

      const newAlertsData: AlertsHierarchy = {}

      const newRawCsvData: RawCsvDatum[] = [
        [
          'Particule',
          'Date',
          'Date alerte',
          'Établissement',
          'Serial',
          'Chambre',
          'Status',
          "Type d'alerte",
          'Commentaire',
          'Alerte validée',
          'URL Listen',
        ],
      ]

      const newAutoValidationCsvData: AutovalidationCsvDatum[] = [
        [
          'Établissement',
          'Chambre',
          'Serial',
          'Date',
          'Heure',
          "Type d'alerte",
          'Commentaire AS',
          'URL Listen',
        ],
      ]

      await Promise.all(
        isoDates.map(async (isoDate) => {
          const alerts = await get(`alerts/${isoDate}`)
          const alertEntries = Object.entries(alerts ?? {})
          if (alertEntries.length !== 0) {
            newAlertsData[isoDate] = alerts
          }
          for (const [serial, serialAlerts] of alertEntries) {
            const { facilityName, room, status } = devices[serial] || {
              facilityName: 'UNKNOWN',
              room: 'UNKNOWN',
              status: 'UNKNOWN',
            }

            for (const alertId in serialAlerts) {
              const {
                date: alertDate,
                type,
                comment,
                validation,
                ownership,
              } = serialAlerts[alertId]

              const listenUrl = `${siteUrl(
                'listen',
              )}/${serial}/${isoDate}/${alertDate}`

              const rawCsvDatum: RawCsvDatum = [
                `${serial}/${isoDate}/${alertDate}`,
                isoDate,
                alertDate,
                facilityName,
                serial,
                room,
                status,
                type,
                comment ? comment : '',
                JSON.stringify(validation?.isValidated),
                listenUrl,
              ]

              newRawCsvData.push(rawCsvDatum)

              if (validation?.isValidated && validation?.uid === automaticUid) {
                let comment: string = ''

                if (ownership?.comment) {
                  const currentComment = ownership.comment
                  if (currentComment) {
                    if (isTextComment(currentComment)) {
                      comment = currentComment.comment
                    } else if (isAudioComment(currentComment)) {
                      comment = `Audio disponible ici : ${currentComment.audioCommentURL}`
                    }
                  }
                }
                const autovalidationCsvDatum: AutovalidationCsvDatum = [
                  facilityName,
                  room,
                  serial,
                  isoDate,
                  alertDate.slice(11, 16),
                  alertTypeString[type],
                  comment,
                  listenUrl,
                ]

                newAutoValidationCsvData.push(autovalidationCsvDatum)
              }
            }
          }
        }),
      )

      setAlertsData(newAlertsData)
      setRawCsvData(newRawCsvData)
      setAutoValidationCsvData(newAutoValidationCsvData)
    }

    computeData()
  }, [startDate, endDate])

  const handleDownload = (exportType: ExportType) => {
    if (rawCsvData === null || autoValidationCsvData === null) {
      throw new Error(`Data should not be null`)
    }

    const element = document.createElement('a')

    function toCsv<T extends RawCsvDatum | AutovalidationCsvDatum>(data: T[]) {
      return data
        .map((datum: T) =>
          datum.map((value: unknown) => JSON.stringify(value)).join(','),
        )
        .join('\n')
    }

    const todayDate = DateTime.now().toISODate()

    let fileName
    let fileContent
    if (exportType === 'csv') {
      fileName = `alerts-${todayDate}.csv`
      fileContent = toCsv(rawCsvData)
    } else if (exportType === 'validated') {
      fileName = `validated-${todayDate}.csv`
      fileContent = toCsv(autoValidationCsvData)
    } else {
      fileName = `alerts-${todayDate}.json`
      fileContent = JSON.stringify(alertsData, null, 2)
    }

    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(fileContent),
    )

    element.setAttribute('download', fileName)

    element.style.display = 'none'
    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)
  }

  return (
    <div className="flex flex-col items-center justify-center gap-3">
      <Button
        variant="secondary"
        disabled={rawCsvData === null}
        onClick={() => {
          handleDownload('csv')
        }}
      >
        Toutes les alertes (CSV)
      </Button>
      <Button
        variant="secondary"
        disabled={alertsData === null}
        onClick={() => {
          handleDownload('json')
        }}
      >
        Toutes les alertes (JSON)
      </Button>
      <Button
        variant="secondary"
        disabled={autoValidationCsvData === null}
        onClick={() => {
          handleDownload('validated')
        }}
      >
        Alertes validées (CSV)
      </Button>
    </div>
  )
}
