import { useLingui } from '@lingui/react/macro'
import { techChannelId } from 'common/channels'
import { useASUser } from '../../../components/UserProvider'
import { translateChannelNames } from '../../Live/Contacts'
import { Conversation } from '../../Live/Conversation'

export const ChatScreen = () => {
  const { facilityId } = useASUser()
  const { t } = useLingui()
  return (
    <div className="flex h-[calc(100vh-60px)] flex-col">
      <Conversation
        conversationId={techChannelId}
        conversationContext={{
          channelId: techChannelId,
          channelName: t(translateChannelNames[techChannelId]),
        }}
        facilityId={facilityId}
      />
    </div>
  )
}
