import {
  Alert,
  alertTypeString,
  BEEP_TYPE_MAPPING,
  isBeepAlert,
  isOldBeepAlert,
  isOldVocalAlert,
  isVocalAlert,
} from 'shared/types/alert'
import { isDefined } from 'shared/utils/defined'
import { ms } from 'shared/utils/time'

export const ALERT_NOTIFICATION_TTL = ms(1, 'hour')

// 🌪️ i18n warning : update accordingly in live/src/common/alert.i18n.ts 🌪️
export function alertDisplayText(alert: Alert) {
  if (isBeepAlert(alert)) {
    const text = isOldBeepAlert(alert)
      ? alert.text.replace(/_/g, ' ')
      : isDefined(alert.beepType)
        ? [
            ...new Set(alert.beepType.map((txt) => BEEP_TYPE_MAPPING[txt])),
          ].join(', ')
        : ''
    return `Bip ${text}`
  } // old version: text, new version translate text

  if (isVocalAlert(alert)) {
    if (isOldVocalAlert(alert)) {
      const filteredKeywords = alert.keywords.filter(
        (keyword) => keyword.toLowerCase() !== 'autre',
      )
      if (filteredKeywords.length > 0) {
        return [
          ...new Set(filteredKeywords.map((keyword) => `"${keyword}"`)),
        ].join(', ')
      }
    } // old format keywords, to remove after migration
    else {
      const filteredKeywords = alert.rawKeywords.filter(
        (keyword) => keyword.length > 0,
      )
      if (filteredKeywords.length > 0) {
        return [
          ...new Set(filteredKeywords.map((keyword) => `"${keyword}"`)),
        ].join(', ')
      }
    } // new version
  }
  return alertTypeString[alert.type]
}
