import { Trans } from '@lingui/react/macro'
import { useMemo, useState } from 'react'
import { Facility } from 'shared/types/live'
import { collator } from 'shared/utils/web/collator'
import { Checkbox } from '../../../components/ui/Checkbox'
import { useASUser } from '../../../components/UserProvider'
import { RoomNamesProvider } from '../../Live/RoomNamesContext'
import { DeviceCard } from './DeviceCard'

type CheckboxWithTextProps = {
  isChecked: boolean
  handleCheck: (b: boolean) => void
}

export function CheckboxWithText({
  handleCheck,
  isChecked,
}: CheckboxWithTextProps) {
  return (
    <div
      className="items-top flex w-full space-x-2 p-2"
      onClick={() => handleCheck(!isChecked)}
    >
      <Checkbox checked={isChecked} />
      <div className="grid gap-1.5 leading-none">
        <div className="text-lg font-medium leading-none">
          <Trans>Afficher uniquement les hors-ligne</Trans>
        </div>
      </div>
    </div>
  )
}

type TechnicianHomeScreenProps = {
  facility: Facility
}

export const DevicesScreen = ({ facility }: TechnicianHomeScreenProps) => {
  const { facilityId } = useASUser()
  const [showOfflineOnly, setShowOfflineOnly] = useState<boolean>(false)

  const rooms = useMemo(
    () => Object.values(facility.devices).map((device) => device.room),
    [facility.devices],
  )

  const devices = useMemo(
    () =>
      Object.entries(facility.devices).sort(
        ([, { room: roomA }], [, { room: roomB }]) =>
          collator.compare(roomA, roomB),
      ),
    [facility.devices],
  )

  return (
    <>
      <CheckboxWithText
        handleCheck={setShowOfflineOnly}
        isChecked={showOfflineOnly}
      />
      <RoomNamesProvider facilityId={facilityId} rooms={rooms}>
        <div className="flex flex-col gap-1">
          {devices.map(([serial, device]) => (
            <DeviceCard
              key={serial}
              device={device}
              serial={serial}
              showOfflineOnly={showOfflineOnly}
            />
          ))}
        </div>
      </RoomNamesProvider>
    </>
  )
}
